import React from 'react';
import { AuthProvider, useAuth} from './AuthContext';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import Affirmation from './Affirmation';

function App() {

  return (
    <AuthProvider>
      <Navbar/>
      <MainContent />
    </AuthProvider>
  );
}

function MainContent() {
  const { isLoggedIn } = useAuth();  // Ensure this is inside a component that is a child of AuthProvider
  return isLoggedIn ? <Dashboard /> : <Affirmation />;
}

export default App;
import React, { useState } from 'react';
import './App.css';
import logo from './images/logo.png';
import LoginModal from './LoginModal';
import '@fortawesome/fontawesome-free/css/all.css';
import { useAuth } from './AuthContext'; // Import useAuth hook
import personIllustration from './images/person.svg'; 


function Navbar() {
    const { isLoggedIn } = useAuth();
    const [isModalOpen, setModalOpen] = useState(false);

    const { logout } = useAuth();

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <nav className="fixed top-0 left-0 right-0 flex items-center justify-between h-20 bg-gray-300/30 shadow-dark-mild px-5">
        <img src={logo} className="h-20 px-4" alt="logo" />
        {isLoggedIn ? (
            <div className="bg-meditate rounded-full">
                <img onClick={logout} className="bg-medidate h-10" src={personIllustration} alt="Affirmative Illustration" />
            </div>
        ) : (
            <button
            onClick={toggleModal}
            className="p-2 rounded-full hover:bg-white/30 transition duration-300 px-4"
            >
                Login
                </button>
        )}
        {isModalOpen && <LoginModal onClose={toggleModal} />}
        </nav>
    );
}

export default Navbar;
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);  // Creates a Context object

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    const login = async (email, password) => {
          const response = await fetch('https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/login', {
              method: 'POST',
              headers: {
                  'Accept':"*/*",
                  "Accept-Encoding": "gzip, deflate, br"
              },
              body: JSON.stringify({ email, password })
          });
  
          if (response.ok) {
              const data = await response.json();
              document.cookie = `token=${data.token}; max-age=3600; path=/`;
              setIsLoggedIn(true);
          } else {
              const errorData = await response.json();
              // Display error message on the login form
              console.error(errorData.error);
              throw new Error(errorData.error);

          }
  };

  const register = async (email, password) => {
          const response = await fetch('https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/register', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Accept':"*/*",
                  "Accept-Encoding": "gzip, deflate, br"
              },
              body: JSON.stringify({ email, password })
          });
  
          if (response.ok) {
              const data = await response.json();
              document.cookie = `token=${data.token}; max-age=3600; path=/`;
              setIsLoggedIn(true);
          } else {
              const errorData = await response.json();
              // Display error message on the login form
              console.error(errorData.error);
              throw new Error(errorData.error);

          }
  };

  const logout = async () => {
    try {
        const response = await fetch('https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/logout', {
            method: 'GET',
            headers: {
                'Authorization': `${document.cookie.split('=')[1]}` // Assuming the token is stored as 'token' in the cookie
            }
        });

        if (response.ok) {
            setIsLoggedIn(false);
        } else {
            const errorData = await response.json();
            console.error('Logout error:', errorData.error);
        }
    } catch (error) {
        console.error('Error during logout:', error);
    }
};
  
    return (
      <AuthContext.Provider value={{ isLoggedIn, login, register, logout }}>
        {children}
      </AuthContext.Provider>
    );
  };

export const useAuth = () => useContext(AuthContext);  // Custom hook to use the context
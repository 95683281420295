import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

const affirmations = [
    {"text": "I am confident and capable.", "image": "images/confident.jpg"},
    {"text": "I believe in myself and my abilities.", "image": "images/believe.jpg"},
    {"text": "Every day is a new opportunity to grow and improve.", "image": "images/opportunity.jpg"},
    {"text": "I am worthy of love, success, and happiness.", "image": "images/worthy.jpg"},
    {"text": "I attract positivity and good energy.", "image": "images/positivity.jpg"},
    {"text": "I am resilient and can overcome any challenge.", "image": "images/resilient.jpg"},
    {"text": "I am grateful for all the good things in my life.", "image": "images/grateful.jpg"},
    {"text": "I am in control of my thoughts and emotions.", "image": "images/control.jpg"},
    {"text": "I am deserving of all the success that comes my way.", "image": "images/success.jpg"},
    {"text": "I embrace change and welcome new experiences.", "image": "images/change.jpg"},
    {"text": "I am free from self-doubt and filled with self-confidence.", "image": "images/confidence.jpg"},
    {"text": "I am growing and becoming a better version of myself each day.", "image": "images/growth.jpg"},
    {"text": "I am a magnet for success and good fortune.", "image": "images/fortune.jpg"},
    {"text": "I am at peace with my past and looking forward to the future.", "image": "images/peace.jpg"},
    {"text": "I am surrounded by love and everything is fine.", "image": "images/love.jpg"},
    {"text": "I am inspired to make wise choices.", "image": "images/wise.jpg"},
    {"text": "I am strong, beautiful, and brave.", "image": "images/strong.jpg"},
    {"text": "I am a creator of my own destiny.", "image": "images/destiny.jpg"},
    {"text": "I am overflowing with joy, vitality, and energy — I’m unstoppable!", "image": "images/joy.jpg"},
    {"text": "I am a positive thinker and only think about the best possible outcome.", "image": "images/positive.jpg"},
    {"text": "I am open to new adventures and experiences.", "image": "images/adventure.jpg"},
    {"text": "I trust in my ability to unlock the way and lead.", "image": "images/lead.jpg"},
    {"text": "I am a beacon of love and compassion.", "image": "images/compassion.jpg"},
    {"text": "I choose to rise above negative feelings and enjoy life.", "image": "images/enjoy.jpg"},
    {"text": "I forgive myself and learn from my mistakes.", "image": "images/forgive.jpg"},
    {"text": "I am a student of life, always eager to learn more.", "image": "images/student.jpg"},
    {"text": "I am a radiant and joyful person.", "image": "images/joyful.jpg"},
    {"text": "I am a powerhouse; I am indestructible.", "image": "images/powerhouse.jpg"},
    {"text": "I possess the qualities needed to be extremely successful.", "image": "images/successful.jpg"},
    {"text": "My body is healthy; my mind is brilliant; my soul is tranquil.", "image": "images/tranquil.jpg"},
    {"text": "I believe I can do anything.", "image": "images/anything.jpg"},
    {"text": "Every day I discover interesting and exciting new paths to pursue.", "image": "images/paths.jpg"},
    {"text": "I understand my potential; I can make my own choices.", "image": "images/potential.jpg"},
    {"text": "I refuse to give up because I haven’t tried all possible ways.", "image": "images/refuse.jpg"},
    {"text": "I am creatively inspired by the world around me.", "image": "images/inspired.jpg"},
    {"text": "My mind is full of brilliant ideas.", "image": "images/brilliant.jpg"},
    {"text": "I put my energy into things that matter to me.", "image": "images/energy.jpg"},
    {"text": "I trust myself to make the right decision.", "image": "images/trust.jpg"},
    {"text": "I am becoming closer to my true self every day.", "image": "images/true_self.jpg"},
    {"text": "Every challenge I face is an opportunity to grow and improve.", "image": "images/challenge.jpg"},
    {"text": "I am at peace with who I am as a person.", "image": "images/peaceful.jpg"},
    {"text": "I make a difference in the world by simply existing in it.", "image": "images/difference.jpg"},
    {"text": "I am learning valuable lessons from myself every day.", "image": "images/lessons.jpg"},
    {"text": "I am at peace with my past and looking at a bright future.", "image": "images/bright_future.jpg"},
    {"text": "I am proud of myself and all that I have accomplished.", "image": "images/proud.jpg"},
    {"text": "I give myself space to grow and learn.", "image": "images/space.jpg"},
    {"text": "I allow myself to be who I am without judgment.", "image": "images/judgment_free.jpg"},
    {"text": "I listen to my intuition and trust my inner guide.", "image": "images/intuition.jpg"},
    {"text": "I accept my emotions and let them serve their purpose.", "image": "images/emotions.jpg"},
    {"text": "I give myself the care and attention that I deserve.", "image": "images/care.jpg"},
    {"text": "My drive and ambition allow me to achieve my goals.", "image": "images/ambition.jpg"},
    {"text": "I share my talents with the world by being my authentic self.", "image": "images/talents.jpg"},
    {"text": "I am good at helping others.", "image": "images/helping.jpg"},
    {"text": "I am always headed in the right direction.", "image": "images/direction.jpg"},
    {"text": "I trust that I am on the right path.", "image": "images/path.jpg"},
    {"text": "I am creatively inspired by the world around me.", "image": "images/creative.jpg"},
    {"text": "I am making my time count.", "image": "images/time.jpg"},
    {"text": "I am at peace with my pacing.", "image": "images/pacing.jpg"},
    {"text": "I am inspired by the possibilities of today.", "image": "images/possibilities.jpg"},
    {"text": "My life is filled with positivity and joy.", "image": "images/life_joy.jpg"},
    {"text": "I am fantastic at what I do.", "image": "images/fantastic.jpg"},
    {"text": "I am making a difference in the world.", "image": "images/making_difference.jpg"},
    {"text": "I am calm, patient, and in control of my emotions.", "image": "images/calm.jpg"},
    {"text": "I love myself fully, including the way I look.", "image": "images/self_love.jpg"},
    {"text": "My life is a blast of growing opportunity because I never stop creating.", "image": "images/opportunity_blast.jpg"},
    {"text": "I am a visionary.", "image": "images/visionary.jpg"},
    {"text": "I act with confidence having a general plan and accept plans are open to adjustment.", "image": "images/confidence_plan.jpg"},
    {"text": "I am safe and sound. All is well.", "image": "images/safe.jpg"},
    {"text": "Everything works out for my highest good.", "image": "images/highest_good.jpg"}
    ]
;

function Affirmation() {
    const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * affirmations.length));

    const handleNewAffirmation = () => {
        setRandomIndex(Math.floor(Math.random() * affirmations.length));
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <p className="text-center font-semibold text-meditate text-3xl ">{affirmations[randomIndex].text}</p>
            <i onClick={handleNewAffirmation} className="p-5 fas fa-redo-alt cursor-pointer text-4xl text-meditate hover:text-medidate-700 transition duration-300 rotate"></i>
       </div>
    );
}

export default Affirmation;

import React from 'react';

const StreakIndicator = ({ streak }) => {
    return (
        <div className="top-10 right-10 flex items-center space-x-2">
            <div className="flex items-center bg-yellow-500 rounded-full p-4 shadow-lg">
                <span className="text-white text-2xl">🔥</span> {/* Flame icon */}
                <span className="text-white font-bold text-xl ml-2">{streak}</span>
            </div>
        </div>
    );
};

export default StreakIndicator;
import React, { useState } from 'react';
import { useAuth } from './AuthContext';

function LoginModal({ onClose }) {
  const { login, register } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);

  const handleErrorMessageChange = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === "modal-backdrop") {
      onClose();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      handleErrorMessageChange("Please enter both email and password.");
      return;
    }
    try {
      if (isRegistering) {
        await register(email, password);
        onClose();  // Close the modal on successful login
      } else {
        await login(email, password);
        onClose();  // Close the modal on successful login
      }
    } catch (error) {
      handleErrorMessageChange(error.message);
    }
  };

  return (
    <div id="modal-backdrop" className="fixed inset-0 bg-black/30 flex justify-center items-center" onClick={handleOutsideClick}>
      <div className="bg-white p-5 rounded-lg relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-xl">✖</button>
        <h2 className="text-lg font-bold">{isRegistering ? 'Register' : 'Login'}</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Email"
            className="border p-2 w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="border p-2 w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="w-full bg-meditate text-white p-2 rounded">
            {isRegistering ? 'Register' : 'Login'}
          </button>
          {errorMessage && <p className="bg-red-500 text-white p-2 rounded">{errorMessage}</p>}
        </form>
        <p className="text-center cursor-pointer text-blue-500" onClick={() => setIsRegistering(!isRegistering)}>
          {isRegistering ? 'Already have an account? Login' : 'Need an account? Register'}
        </p>
      </div>
    </div>
  );
}

export default LoginModal;
import React, { useState, useEffect } from 'react';
import sleepIllustration from './images/sleep.svg'; // Import sleep.svg illustration
import moodIllustration from './images/mood.svg'; // Import sleep.svg illustration
import gratitudeIllustration from './images/gratitude.svg'; 
import affirmativeIllustration from './images/affirmative.svg'; 
import StreakIndicator from './StreakIndicator';
import learningIllustration from './images/learning.svg'
import axios from 'axios'; // Assuming you're using axios for API calls


const affirmations = [
    {"text": "I am confident and capable.", "image": "images/confident.jpg"},
    {"text": "I believe in myself and my abilities.", "image": "images/believe.jpg"},
    {"text": "Every day is a new opportunity to grow and improve.", "image": "images/opportunity.jpg"},
    {"text": "I am worthy of love, success, and happiness.", "image": "images/worthy.jpg"},
    {"text": "I attract positivity and good energy.", "image": "images/positivity.jpg"},
    {"text": "I am resilient and can overcome any challenge.", "image": "images/resilient.jpg"},
    {"text": "I am grateful for all the good things in my life.", "image": "images/grateful.jpg"},
    {"text": "I am in control of my thoughts and emotions.", "image": "images/control.jpg"},
    {"text": "I am deserving of all the success that comes my way.", "image": "images/success.jpg"},
    {"text": "I embrace change and welcome new experiences.", "image": "images/change.jpg"},
    {"text": "I am free from self-doubt and filled with self-confidence.", "image": "images/confidence.jpg"},
    {"text": "I am growing and becoming a better version of myself each day.", "image": "images/growth.jpg"},
    {"text": "I am a magnet for success and good fortune.", "image": "images/fortune.jpg"},
    {"text": "I am at peace with my past and looking forward to the future.", "image": "images/peace.jpg"},
    {"text": "I am surrounded by love and everything is fine.", "image": "images/love.jpg"},
    {"text": "I am inspired to make wise choices.", "image": "images/wise.jpg"},
    {"text": "I am strong, beautiful, and brave.", "image": "images/strong.jpg"},
    {"text": "I am a creator of my own destiny.", "image": "images/destiny.jpg"},
    {"text": "I am overflowing with joy, vitality, and energy — I’m unstoppable!", "image": "images/joy.jpg"},
    {"text": "I am a positive thinker and only think about the best possible outcome.", "image": "images/positive.jpg"},
    {"text": "I am open to new adventures and experiences.", "image": "images/adventure.jpg"},
    {"text": "I trust in my ability to unlock the way and lead.", "image": "images/lead.jpg"},
    {"text": "I am a beacon of love and compassion.", "image": "images/compassion.jpg"},
    {"text": "I choose to rise above negative feelings and enjoy life.", "image": "images/enjoy.jpg"},
    {"text": "I forgive myself and learn from my mistakes.", "image": "images/forgive.jpg"},
    {"text": "I am a student of life, always eager to learn more.", "image": "images/student.jpg"},
    {"text": "I am a radiant and joyful person.", "image": "images/joyful.jpg"},
    {"text": "I am a powerhouse; I am indestructible.", "image": "images/powerhouse.jpg"},
    {"text": "I possess the qualities needed to be extremely successful.", "image": "images/successful.jpg"},
    {"text": "My body is healthy; my mind is brilliant; my soul is tranquil.", "image": "images/tranquil.jpg"},
    {"text": "I believe I can do anything.", "image": "images/anything.jpg"},
    {"text": "Every day I discover interesting and exciting new paths to pursue.", "image": "images/paths.jpg"},
    {"text": "I understand my potential; I can make my own choices.", "image": "images/potential.jpg"},
    {"text": "I refuse to give up because I haven’t tried all possible ways.", "image": "images/refuse.jpg"},
    {"text": "I am creatively inspired by the world around me.", "image": "images/inspired.jpg"},
    {"text": "My mind is full of brilliant ideas.", "image": "images/brilliant.jpg"},
    {"text": "I put my energy into things that matter to me.", "image": "images/energy.jpg"},
    {"text": "I trust myself to make the right decision.", "image": "images/trust.jpg"},
    {"text": "I am becoming closer to my true self every day.", "image": "images/true_self.jpg"},
    {"text": "Every challenge I face is an opportunity to grow and improve.", "image": "images/challenge.jpg"},
    {"text": "I am at peace with who I am as a person.", "image": "images/peaceful.jpg"},
    {"text": "I make a difference in the world by simply existing in it.", "image": "images/difference.jpg"},
    {"text": "I am learning valuable lessons from myself every day.", "image": "images/lessons.jpg"},
    {"text": "I am at peace with my past and looking at a bright future.", "image": "images/bright_future.jpg"},
    {"text": "I am proud of myself and all that I have accomplished.", "image": "images/proud.jpg"},
    {"text": "I give myself space to grow and learn.", "image": "images/space.jpg"},
    {"text": "I allow myself to be who I am without judgment.", "image": "images/judgment_free.jpg"},
    {"text": "I listen to my intuition and trust my inner guide.", "image": "images/intuition.jpg"},
    {"text": "I accept my emotions and let them serve their purpose.", "image": "images/emotions.jpg"},
    {"text": "I give myself the care and attention that I deserve.", "image": "images/care.jpg"},
    {"text": "My drive and ambition allow me to achieve my goals.", "image": "images/ambition.jpg"},
    {"text": "I share my talents with the world by being my authentic self.", "image": "images/talents.jpg"},
    {"text": "I am good at helping others.", "image": "images/helping.jpg"},
    {"text": "I am always headed in the right direction.", "image": "images/direction.jpg"},
    {"text": "I trust that I am on the right path.", "image": "images/path.jpg"},
    {"text": "I am creatively inspired by the world around me.", "image": "images/creative.jpg"},
    {"text": "I am making my time count.", "image": "images/time.jpg"},
    {"text": "I am at peace with my pacing.", "image": "images/pacing.jpg"},
    {"text": "I am inspired by the possibilities of today.", "image": "images/possibilities.jpg"},
    {"text": "My life is filled with positivity and joy.", "image": "images/life_joy.jpg"},
    {"text": "I am fantastic at what I do.", "image": "images/fantastic.jpg"},
    {"text": "I am making a difference in the world.", "image": "images/making_difference.jpg"},
    {"text": "I am calm, patient, and in control of my emotions.", "image": "images/calm.jpg"},
    {"text": "I love myself fully, including the way I look.", "image": "images/self_love.jpg"},
    {"text": "My life is a blast of growing opportunity because I never stop creating.", "image": "images/opportunity_blast.jpg"},
    {"text": "I am a visionary.", "image": "images/visionary.jpg"},
    {"text": "I act with confidence having a general plan and accept plans are open to adjustment.", "image": "images/confidence_plan.jpg"},
    {"text": "I am safe and sound. All is well.", "image": "images/safe.jpg"},
    {"text": "Everything works out for my highest good.", "image": "images/highest_good.jpg"}
    ]
;

const defaultEntry = {
  mood: 'neutral',
  learning:'',
  gratitude: '',
  sleep: {
      hours: 0,
      minutes: 0,
      quality: 'medium',
  },
  greatThings: ['', '', ''],
  affirmation: '',
};

function Dashboard() {
  const [mood, setMood] = useState('neutral');
  const [gratitude, setGratitude] = useState('');
  const [learning, setLearning] = useState('');
  const [sleepHours, setSleepHours] = useState('');
  const [sleepMinutes, setSleepMinutes] = useState('');
  const [sleepQuality, setSleepQuality] = useState('');
  const [streakDays, setStreakDays] = useState('');
  const [selectedEntry, setSelectedEntry] = useState(defaultEntry);
  const [showDropdown, setShowDropdown] = useState(false);
  const [previousEntries, setPreviousEntries] = useState([]);


  useEffect(() => {
    fetchAllEntries();
    // eslint-disable-next-line
  },[]); // Empty dependency array means this runs once on mount

  const fetchAllEntries = async () => {
    try {
        const response = await axios.get('https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/journal', {
            headers: {
                'Authorization': `${document.cookie.split('=')[1]}`
            }
        });
        const entries = response.data;

        const today = new Date().toISOString().split('T')[0]; // Format to YYYY-MM-DD

        if (!entries || entries.length === 0) {
            // No entries found, create a new one
            const newEntry = await createJournalEntry(); // Create a new entry
            setPreviousEntries([newEntry]); // Add the new entry to the previous entries
            setSelectedEntry(newEntry);
            fetchJournalEntry(newEntry.Id); // Fetch the newly created entry
        } else {
            // Entries found, check for today's entry
            const todayEntry = entries.find(entry => new Date(entry.date).toISOString().split('T')[0] === today);
            if (todayEntry) {
                fetchJournalEntry(todayEntry.Id); // Fetch today's entry data
            } else {
                // Today's entry does not exist, create a new one
                const newEntry = await createJournalEntry(); // Create a new entry
                setPreviousEntries([...entries, newEntry]); // Update the entries list
                setSelectedEntry(newEntry);
                fetchJournalEntry(newEntry.Id); // Fetch the newly created entry
            }
            setPreviousEntries(entries); // Update the previous entries state
        }
        calculateStreak(entries); // Calculate streak after fetching entries

    } catch (error) {
        console.error('Error fetching journal entries:', error);
    }
  };


  const calculateStreak = (entries) => {
    let streak = 0;

    // Sort entries by date in ascending order
    const sortedEntries = entries.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Check if there are any entries
    if (sortedEntries.length === 0) {
        setStreakDays(streak); // No entries, streak is 0
        return;
    }

    // Start from the most recent entry
    let lastEntryDate = new Date(sortedEntries[sortedEntries.length - 1].date).setHours(0, 0, 0, 0);
    streak++; // Count the last entry

    // Iterate through the entries in reverse order
    for (let i = sortedEntries.length - 2; i >= 0; i--) {
        const currentEntryDate = new Date(sortedEntries[i].date).setHours(0, 0, 0, 0);

        // Check if the current entry is the previous day
        if (currentEntryDate === lastEntryDate - 86400000) { // 86400000 ms = 1 day
            streak++;
            lastEntryDate = currentEntryDate; // Update lastEntryDate to current
        } else {
            break; // Stop if the streak is broken
        }
    }

    setStreakDays(streak); // Update the streak state
};

  const [greatThings] = useState(['', '', '']); // Initial state with 3 empty items

    // Create a journal entry object
    const createJournalEntry = async () => {
      const entry = {
          mood,
          learning,
          gratitude,
          sleep: {
              hours: parseInt(sleepHours),
              minutes: parseInt(sleepMinutes),
              quality: sleepQuality,
          },
          greatThings,
          affirmation: '', // Set affirmation if needed
      };

      try {
        const response = await axios.post('https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/journal', entry, {
            headers: {
                'Authorization': `${document.cookie.split('=')[1]}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data.entry; // Return the created entry
      } catch (error) {
          console.error('Error creating journal entry:', error);
          return null; // Return null in case of error
      }
  };

 
  const fetchJournalEntry = async (date) => {
    try {
        const response = await axios.get(`https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/journal/${date}`, {
            headers: {
                'Authorization': `${document.cookie.split('=')[1]}`
            }
        });
        const entry = response.data;
        setSelectedEntry(entry);
    } catch (error) {
        console.error('Error fetching journal entry:', error);
        
    }
  };

  const updateJournalEntry = async () => {
    if (selectedEntry ===  "undefined" || selectedEntry.Id === "undefined"){
      return;
    }
    try {
        await axios.put(`https://uarewell-backend-service-nufwt5iqja-ew.a.run.app/api/journal/${selectedEntry.Id}`, selectedEntry, {
            headers: {
                'Authorization': `${document.cookie.split('=')[1]}`,
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        console.error('Error updating journal entry:', error);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (selectedEntry) // This means if Fred is not default empty state.
      updateJournalEntry();
      // eslint-disable-next-line
  }, [selectedEntry])

    const handleDateChange = (date) => {
      const selectedEntry = previousEntries.find(entry => entry.date === date);
      if (selectedEntry) {
          fetchJournalEntry(selectedEntry.Id); // Fetch entry by ID
      }
      setShowDropdown(false);
    };   

    const handleMoodChange = (newMood) => {
      setMood(newMood);
      setSelectedEntry(prev => ({ ...prev, mood: newMood }));
      
  };

  const handleGratitudeChange = (event) => {
      const newGratitude = event.target.value;
      setGratitude(newGratitude);
      setSelectedEntry(prev => ({ ...prev, gratitude: newGratitude }));
      
  };

  const handleLearningChange = (event) => {
      const newLearning = event.target.value;
      setLearning(newLearning);
      setSelectedEntry(prev => ({ ...prev, learning: newLearning }));
  };

  const handleSleepHoursChange = (event) => {
      const newHours = event.target.value;
      setSleepHours(newHours);
      setSelectedEntry(prev => ({ ...prev, sleep: { ...prev.sleep, hours: parseInt(newHours) } }));
      
  };

  const handleSleepMinutesChange = (event) => {
      const newMinutes = event.target.value;
      setSleepMinutes(newMinutes);
      setSelectedEntry(prev => ({ ...prev, sleep: { ...prev.sleep, minutes: parseInt(newMinutes) } }));
      
  };

  const handleSleepQualityChange = (event) => {
      const newQuality = event.target.value;
      setSleepQuality(newQuality);
      setSelectedEntry(prev => ({ ...prev, sleep: { ...prev.sleep, quality: newQuality } }));
      
  };

  const handleGreatThingsChange = (index, value) => {
      const updatedGreatThings = [...selectedEntry.greatThings];
      updatedGreatThings[index] = value;
      setSelectedEntry(prev => ({ ...prev, greatThings: updatedGreatThings }));
      
  };

  const addGreatThing = () => {
      setSelectedEntry(prev => ({ ...prev, greatThings: [...prev.greatThings, ''] }));
      
  };
  const [randomIndex] = useState(Math.floor(Math.random() * affirmations.length));
  
  return (
    <div className="flex flex-col justify-center items-center mt-20 sm:mt-20">
        <div className='flex items-center space-x-4 justify-between w-full'>
            <div className='items-left justify-left ml-5 mt-5'><StreakIndicator streak={streakDays}/></div>
            <img className="flex items-center justify-center h-20" src={moodIllustration} alt="Mood Illustration" />
            <div className="relative">
                  <p 
                        className="pr-2 text-lg font-bold text-meditate p-2 text-right rounded-3xl bg-white/30 cursor-pointer"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        {new Date(selectedEntry.date).toLocaleDateString() || 'Select Date'}
                        <i className={`text-lg fas fa-chevron-down ml-2 ${showDropdown ? 'text-gray-700' : 'text-gray-400'}`}></i>
                    </p>
                    {showDropdown && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                            {previousEntries.map((entry) => (
                                <p 
                                    key={entry.Id} 
                                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleDateChange(entry.date)}
                                >
                                    {new Date(entry.date).toLocaleDateString()}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
        </div>
      <p className='font-bold text-meditate text-2xl'> How do you feel today?</p>
      <div className="flex items-center space-x-4 mt-4 p-2 rounded-3xl bg-white/30">
        <i className={`text-2xl fas fa-smile ${selectedEntry.mood === 'happy' ? 'text-green-500' : 'text-gray-400 cursor-pointer'}`} onClick={() => handleMoodChange('happy')}></i>
        <i className={`text-2xl fas fa-meh ${selectedEntry.mood === 'neutral' ? 'text-yellow-500' : 'text-gray-400 cursor-pointer'}`} onClick={() => handleMoodChange('neutral')}></i>
        <i className={`text-2xl fas fa-frown ${selectedEntry.mood === 'sad' ? 'text-red-500' : 'text-gray-400 cursor-pointer'}`} onClick={() => handleMoodChange('sad')}></i>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mt-8">
        <div className="bg-white bg-opacity-20 rounded-lg shadow-md p-8">
        <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold mb-4">Gratitude</h2>
            <div className="mt-0">
                <img className="h-20 px-4" src={gratitudeIllustration} alt="Gratitude Illustration" />
            </div>
        </div>
          <p>What are you grateful for today?</p>
          <textarea className="w-full h-32 bg-transparent border border-gray-300 rounded p-2" value={selectedEntry.gratitude} onChange={handleGratitudeChange} placeholder="Enter your gratitude here"></textarea>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-md p-8">
        <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold mb-4">Daily Learning</h2>
            <div className="mt-0">
                <img className="h-20 px-4" src={learningIllustration} alt="Learning Illustration" />
            </div>
        </div>
          <p>What did you learn today?</p>
          <textarea className="w-full h-32 bg-transparent border border-gray-300 rounded p-2" value={selectedEntry.learning} onChange={handleLearningChange} placeholder="What did you learn today?"></textarea>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-md p-8">
        <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold mb-4">Your daily affirmation</h2>
                <div className="mt-0">
                    <img className="h-20 px-4" src={affirmativeIllustration} alt="Affirmative Illustration" />
                </div>
            </div>
          <p className=''>"{affirmations[randomIndex].text}"</p>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-md p-8">
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold mb-4">Which great things did you experience today?</h2>
          </div>
          {selectedEntry.greatThings.map((item, index) => (
            <input
              key={index}
              type="text"
              className="w-full bg-transparent border border-gray-300 rounded p-2 mb-2"
              value={item}
              onChange={(e) => handleGreatThingsChange(index, e.target.value)}
              placeholder={`Great Thing ${index + 1}`}
            />
          ))}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2" onClick={addGreatThing}>
            Add Great Thing
          </button>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-md p-8">
        <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold mb-4">Sleep Evaluation</h2>
            <div className="mt-0">
                <img className="h-20 px-4" src={sleepIllustration} alt="Sleep Illustration" />
            </div>
        </div>
          <div className="flex justify-between mb-4">
            <div>
              <p className='text-gray-500'>Sleep Duration</p>
              <div className="flex items-center">
                <input type="number" className="w-16 bg-white/30 rounded p-2 mr-2" value={selectedEntry.sleep.hours} onChange={handleSleepHoursChange} placeholder="Hours"></input>
                <span>hrs</span>
                <input type="number" className="w-16 bg-white/30 rounded p-2 ml-2" value={selectedEntry.sleep.minutes} onChange={handleSleepMinutesChange} placeholder="Minutes"></input>
                <span>mins</span>
              </div>
            </div>
            <div>
              <p className='text-gray-500'>Sleep Quality</p>
              <select className="w-32 bg-white/30  rounded p-2" value={selectedEntry.sleep.quality} onChange={handleSleepQualityChange}>
                <option value="like_a_stone">Like a Stone</option>
                <option value="medium">Medium</option>
                <option value="bad">Bad</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;